import React from 'react'
import { Link } from 'gatsby'
import { Link as ScrollLink } from "react-scroll"
import { Disclosure } from "@headlessui/react"
import { PrimaryButton } from '../../common/button'
import { menuList, socialLinks } from './menu.js'

const Navbar = () => {
  return (
    <>
      <Disclosure
        as="nav"
        className={`absolute left-0 top-0 w-full z-50 `}
      >
        {({ open }) => (
          <>
            <div className='gap-4 justify-between hidden lg:flex lg:flex-row-reverse max-w-screen-2xl w-full mx-auto py-8 font-montserrat-black text-xl relative items-center px-12' >
              <div className='flex flex-col gap-2 items-end'>
                <div className='flex gap-2'>
                  {socialLinks.map((link, idx) => (
                    <a href={link.link} target="_blank" rel="noreferrer" key={idx}>
                      <PrimaryButton className="font-montserrat-black py-1 px-4 md:px-8 hover:text-app-red text-sm md:text-base">
                        {link.title}
                      </PrimaryButton>
                    </a>
                  ))}
                </div>
              </div>
              <ul className='flex gap-8 items-center justify-center'>
                {menuList.map((menu, idx) => (
                  menu.type === "inner" ?
                    <ScrollLink
                      to={menu.path}
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      key={idx}
                    >
                      <p className='cursor-pointer text-white hover:text-app-red'>
                        {menu.title}
                      </p>
                    </ScrollLink>
                    :
                    <Link to={menu.path} key={idx}>
                      {menu.title}
                    </Link>
                ))}
              </ul>
              <Link to='/' className='xl:absolute flex left-1/2 top-1/2 xl:transform xl:-translate-x-1/2 xl:-translate-y-1/2'>
                <img src='/logo.png' alt='top' />
              </Link>
            </div>
            <div className='absolute right-4 sm:right-5 md:right-7 top-4 sm:top-5 md:top-7 lg:hidden'>
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-secondary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <img src="/home/icon_close.png" alt="close icon" className="h-6 sm:h-8 md:h-9 z-20" />
                ) : (
                  <img src="/home/icon_hambuger.png" alt="hambuger icon" className='h-6 sm:h-8 md:h-9' />
                )}
              </Disclosure.Button>
            </div>
            <Disclosure.Panel className="lg:hidden bg-app-purple">
              <div className='flex gap-4 justify-between flex-col lg:hidden w-full mx-auto py-8 font-montserrat-black text-xl relative items-center px-4 sm:px-8 pt-20' >
                <div className='flex flex-col items-center gap-4 w-full'>
                  {menuList.map((menu, idx) => (
                    menu.type === "inner" ?
                      <ScrollLink
                        to={menu.path}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        key={idx}
                      >
                        <p className='cursor-pointer text-center text-white hover:text-app-red'>
                          {menu.title}
                        </p>
                      </ScrollLink>
                      :
                      <Link to={menu.path} key={idx}>
                        {menu.title}
                      </Link>
                  ))}
                </div>
                <div className='flex justify-between w-full gap-4 px-6 pt-4 md:w-2/3 mx-auto'>
                  {socialLinks.map((link, idx) => (
                    <a href={link.link} target="_blank" rel="noreferrer" className='w-1/2' key={idx}>
                      <PrimaryButton className="font-montserrat-black py-1 text-base hover:text-app-red">
                        {link.title}
                      </PrimaryButton>
                    </a>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}

export default Navbar
