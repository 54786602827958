import React from "react";

export const TeamItem = (props) => {
  const {
    member,
    className
  } = props

  return (
    <div className={`flex-col flex bg-app-yellow-light rounded-xl p-2 text-app-purple space-y-2 ${className}`}>
      <div className="rounded-lg aspect-square relative">
        <img src={member.image} alt={member.name} className="w-full"/>
        { member.logo !== "" && (
          <img src={member.logo} alt="logo img" className="w-12 xs:w-16 md:w-12 lg:w-16 xl:w-18 absolute -right-6 -top-6 xs:-top-8 md:-top-6 lg:-top-8 xl:-top-8"/>
        )}
      </div>
      <div className="flex flex-col flex-1 px-2 md:px-0 xl:px-2">
        <p className={`flex-1 ${member.fontSize} font-montserrat-black break-words uppercase`}>{member.name}</p>
        <div className="flex justify-between items-center mt-4">
          <p className="font-montserrat-black text-sm sm:text-base xl:text-lg">{member.role}</p>
          {member.twitter !== "" && (
            <a href={member.twitter} target="_blank" rel="noreferrer">
              <img src="/home/icon_twitter.png" alt="twitter img" className="w-6"/>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}