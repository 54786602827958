import React from 'react'

export const Input = (props) => {

  const {
    value,
    setValue,
    name="",
    type = "text",
    hint = "",
    className
  } = props

  return (
    <input type={type} value={value} name={name} onChange={(e) => setValue(e.target.value)} className={`${className} outline-none w-full text-app-purple placeholder:text-center placeholder:text-app-gray px-6`} placeholder={hint} />
  )
}