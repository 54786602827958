export const memberList = [
  {
    name: "TIMMY HAM",
    role: "Founder / Artist",
    image: "/home/team_1.png",
    logo: "/home/team_1_logo.png",
    twitter: "Http://twitter.com/timmyham",
    fontSize: "text-xl sm:text-2xl xl:text-3xl"
  },
  {
    name: "TIMOTHY JOOSTE",
    role: "Advice",
    image: "/home/team_2.png",
    logo: "/home/team_2_logo.png",
    twitter: "https://twitter.com/timothyjooste",
    fontSize: "text-xl sm:text-2xl xl:text-3xl"
  },
  {
    name: "JUSTIN STEWART",
    role: "Design",
    image: "/home/team_3.png",
    logo: "/home/team_3_logo.png",
    twitter: "https://twitter.com/Justifii",
    fontSize: "text-xl sm:text-2xl xl:text-3xl"
  },
  {
    name: "KIENOBI",
    role: "Media",
    image: "/home/team_4.png",
    logo: "/home/team_4_logo.png",
    twitter: "https://twitter.com/kienobimedia",
    fontSize: "text-xl sm:text-2xl xl:text-3xl"
  },
  {
    name: "Grayson Jensen",
    role: "Media",
    image: "/home/team_12.png",
    logo: "",
    twitter: "https://twitter.com/graysonjensen",
    fontSize: "sm:text-xl xl:text-3xl"
  },
  {
    name: "Berk Avigdor",
    role: "Community Manager",
    image: "/home/team_11.png",
    logo: "/home/team_11_logo.png",
    twitter: "https://twitter.com/AvigdorBerk",
    fontSize: "sm:text-xl xl:text-3xl"
  },
  {
    name: "@IAMSNELLEN",
    role: "Community ",
    image: "/home/team_5.png",
    logo: "",
    twitter: "",
    fontSize: "sm:text-xl xl:text-3xl"
  },
  {
    name: "@OXSWIPER",
    role: "Community",
    image: "/home/team_6.png",
    logo: "",
    twitter: "",
    fontSize: "sm:text-xl xl:text-3xl"
  },
  {
    name: "@BLUEAMCAT",
    role: "Community",
    image: "/home/team_7.png",
    logo: "",
    twitter: "",
    fontSize: "sm:text-xl xl:text-3xl"
  },
  {
    name: "WHITNEY",
    role: "Community",
    image: "/home/team_10.png",
    logo: "",
    twitter: "https://twitter.com/wanderlustwab",
    fontSize: "sm:text-xl xl:text-3xl"
  },
]

export const logoList = [
  {
    name: "Polo",
    image: "/home/logo_polo.png"
  },
  {
    name: "Vans",
    image: "/home/logo_vans.png"
  },
  {
    name: "Nike",
    image: "/home/logo_nike.png"
  },
  {
    name: "Logitech",
    image: "/home/logo_logitech.png"
  },
  {
    name: "Nfl",
    image: "/home/logo_nfl.png"
  },
  {
    name: "Im",
    image: "/home/logo_im.png"
  },
  {
    name: "Adobe",
    image: "/home/logo_adobe.png"
  },
  {
    name: "Lexus",
    image: "/home/logo_lexus.png"
  },
  {
    name: "Samsung",
    image: "/home/logo_samsung.png"
  },
  {
    name: "Fox",
    image: "/home/logo_fox.png"
  },
]

export const roadmapList = [
  {
    id: "sloth",
    title: "Sloth Fam",
    image: "/home/btn_sloth.png",
    color: "#88F7E6",
    position: "left-0 top-0",
    description: `Family is not a term we take lightly. The IAMSLOTH Community is built around love and art. We’ve established amazing friendships, celebrated our best moments as well as been there for one another during the bad ones. We can proudly say these friendships turned into a beautiful Family. And that is the most important thing for us, Family will always be the core of this project. Everyone is more than welcome to join and share this amazing experience with us. Combined with amazing art by our established artist and founder Timmy Ham aka Sloth, we brought the best of both worlds together! From beautiful and versatile NFT’s, custom digital arts all the way to custom pieces of art and merchandise, no doubt you are in the right place to fulfill your hunger for Web3. We’re very thankful for our huge audience, and to be able to use our voice for what’s important. We welcome you with our open, long sloth arms!`,
    images: [
      "/home/roadmap/sloth/1.png",
      "/home/roadmap/sloth/2.png",
      "/home/roadmap/sloth/3.png",
    ]
  },
  {
    id: "toys",
    title: "Toys",
    image: "/home/btn_toys.png",
    color: "#FF2F5B",
    position: "left-0 top-1/2 transform -translate-y-12",
    description: `Timmy is well known for his Custom Painted Toys. Well get ready for some Custom Sloth Vinyl Toys, because they are happening! These collectable collection will be very exclusive and our holders will have full access to them. Chilled Sloth just got real!`,
    images: [
      "/home/roadmap/toys/1.png",
      "/home/roadmap/toys/2.png",
    ]
  },
  {
    id: "merch",
    title: "Merch",
    image: "/home/btn_merch.png",
    color: "#FFB396",
    position: "left-1/3 top-1/3 transform -translate-y-4 translate-x-2",
    description: `OH YES! Lots and lots of Merch. This is something we can never get bored of, literally too much ideas. Our community are already rocking their Exclusive Sloth T-shirts, Hats, Pins and Stickers that won’t be remade. These were just the beginning made for our early members. New Merch ideas are shaping constantly, and awesome Collab Merchandises are in the making. (More on that under the Collab part) Chilled Sloth NFT Holders gain access to Exclusive Merch only they can get.`,
    images: [
      "/home/roadmap/merch/1.png",
      "/home/roadmap/merch/2.png",
      "/home/roadmap/merch/3.png",
      "/home/roadmap/merch/4.png",
      "/home/roadmap/merch/5.png",
      "/home/roadmap/merch/6.png",
    ]
  },
  {
    id: "physicals",
    title: "Physicals",
    image: "/home/btn_physicals.png",
    color: "#E399FF",
    position: "right-0 top-1/4 transform -translate-y-16",
    description: `You can take your love for the Sloth Art to the next step! Our Holders exclusively can purchase or win custom physical pieces hand made by Timmy. Canvases, Original Art Pieces Timmy has made along the years, custom Shoes, Game Consoles, and much more are waiting for our holders. `,
    images: [
      "/home/roadmap/physicals/1.png",
      "/home/roadmap/physicals/2.png",
      "/home/roadmap/physicals/3.png",
      "/home/roadmap/physicals/4.png",
      "/home/roadmap/physicals/5.png",
      "/home/roadmap/physicals/6.png",
      "/home/roadmap/physicals/7.png",
      "/home/roadmap/physicals/8.png",
    ]
  },
  {
    id: "collabs",
    title: "Collabs",
    image: "/home/btn_collabs.png",
    color: "#DFFF5E",
    position: "left-0 bottom-1/4 transform translate-y-24 sm:translate-y-32",
    description: `Innovation, Uniqueness, Quality, Inspiration. These are big values, and we love to Collab with the leading Web3 Projects and communities, and bring something fresh to life with all these values for our community. You won’t see random collabs with your big ordinary Web2 brand here. We have already started big collabs underway, check our Discord and you might find Sneak Peaks and Easter Eggs laying around.`,
    images: [
      "/home/roadmap/collabs/1.png",
      "/home/roadmap/collabs/2.png",
      "/home/roadmap/collabs/3.png",
      "/home/roadmap/collabs/4.png",
      "/home/roadmap/collabs/5.png",
      "/home/roadmap/collabs/6.png",
      "/home/roadmap/collabs/7.png",
      "/home/roadmap/collabs/8.png",
      "/home/roadmap/collabs/9.png",
      "/home/roadmap/collabs/10.png",
      "/home/roadmap/collabs/11.png",
    ]
  },
  {
    id: "events",
    title: "Events",
    image: "/home/btn_events.png",
    color: "#E5519B",
    position: "right-0 bottom-1/4 transform -translate-y-4 sm:-translate-y-8",
    description: `Your Chilled Sloth Experience will carry out from Digital to our World. We’re planning IRL events where our community can chill together, know one another, have fun with Sloth and carry out their love for the art out in the streets. And coffee, yes our Sloth’s are highly caffeinated ironically. Besides from social events, we’re also attending big Web3 events like VeeCon, NFTNYC. Fun Fact: A huge Sloth Canvas made out of 555 smaller canvases, hand-made by Timmy, was the main attraction Art on VeeCon! We’ll be attending many more huge industry events like these, and our Holders will be able to get a chance to be there with us.`,
    images: [
      "/home/roadmap/events/1.png",
      "/home/roadmap/events/2.png",
      "/home/roadmap/events/3.png",
      "/home/roadmap/events/4.png",
      "/home/roadmap/events/5.png",
    ]
  }
]

export const familyGallery = [
  "/home/family_1.png",
  "/home/img_1.jpg",
  "/home/img_2.jpg",
  "/home/img_3.jpg",
  "/home/img_4.jpg",
  "/home/img_5_3.jpg"
]

export const galleryData = [
  [
    "1_1",
    "1_2",
    "1_3",
    "1_4",
    "1_5",
    "1_6",
    "1_7",
    "1_8"
  ],
  [
    "2_1",
    "2_2",
    "2_3",
    "2_4",
    "2_5",
    "2_6",
    "2_7",
    "2_8"
  ],
  [
    "1_1",
    "1_2",
    "1_3",
    "1_4",
    "1_5",
    "1_6",
    "1_7",
    "1_8",
    "2_1",
    "2_2",
    "2_3",
    "2_4",
    "2_5",
    "2_6",
    "2_7"
  ],
]
