import React from 'react'
import Navbar from '../navbar'

const Header = () =>{
  return(
    <header>
      <Navbar />
    </header>
  )
}

export default Header