import React from 'react'

export const PrimaryButton = (props) => {
  const {
    children,
    className = "text-8xl px-8",
    action,
    type = "button"
  } = props

  return (
    <button className={`${className}  w-full text-app-purple bg-app-yellow active:scale-95 focus:transform duration-100`} type={type} onClick={action} >
      {children}
    </button>
  )
}
