import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Footer from './footer'
import Header from './header'

const Layout = (props) =>{
  const {
    title = "Sloths",
    children,
  } = props
  return(
   <Fragment>
     <Helmet>
       <title>{title}</title>
     </Helmet>
     <Header />
     <main>
       <div className='bg-app-purple'>
        {children}
       </div>
     </main>
     <Footer />
   </Fragment>
  )
}

export default Layout