import React, { useEffect, useRef, useState } from "react";
import { Link as ScrollLink } from "react-scroll"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lightbox from 'react-image-lightbox'
import { PrimaryButton } from "../../common/button"
import { Input } from "../../common/input"
import { TeamItem } from "../../common/item"
import { logoList, galleryData, memberList, roadmapList, familyGallery } from "./data";
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "react-image-lightbox/style.css"
import "./home.scss"

gsap.registerPlugin(ScrollTrigger)

const HomePage = () => {
  const HomeSection = () => {
    return (
      <div className="home-header bg-app-blue-light relative">
        <div className="w-full z-0">
          <video autoPlay muted loop playsInline className="w-full mx-auto h-full">
            <source src="/home/hero-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute left-0 top-0 w-full h-full bg-black opacity-50"></div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 flex items-center justify-center cursor-pointer z-30">
            <ScrollLink
              to="signup-container"
              spy={true}
              smooth={true}
              offset={0}
              duration={1500}
            >
              <div className="flex items-center justify-center flex-col w-16 sm:w-24 lg:w-28 2xl:w-32 scroll-animate">
                <img src="/home/btn_scroll.png" alt="arrow" className="w-full" />
              </div>
            </ScrollLink>
          </div>
        </div>
        <img src="/home/text_sloth.png" alt="chill" className="w-full absolute -bottom-0" />
      </div>
    )
  }

  const VideoSection = () => {
    const [isPlay, setIsPlay] = useState(false)
    const videoRef = useRef(null)

    const playHandler = () => {
      if (isPlay) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }

      setIsPlay(!isPlay)
    }

    return (
      <div className="video-section flex pt-20 sm:pt-28 lg:pt-24 relative">
        <div className="lg:container flex flex-col items-center justify-center mx-auto  px-0 gap-4 sm:gap-6 md:gap-10 lg:gap-12 xl:gap-16">
          <div className="flex flex-col items-center text-white font-bold uppercase">
            <div className="flex justify-center items-center gap-3 md:gap-4 xl:gap-6 sm:text-2xl lg:text-3xl xl:text-4xl font-montserrat">
              <h3>Create</h3>
              <h3 className="text-app-red">/</h3>
              <h3>Adventure</h3>
              <h3 className="text-app-red">/</h3>
              <h3>Community</h3>
            </div>
            <img src="/home/text_everyday.png" alt="everyday icon" className="w-1/2 sm:w-1/3 xl:w-1/2 mt-2 sm:mt-4 xl:mt-6"/>
          </div>
          <img src="/home/text_chill.png" alt="chill" className="w-full px-6 sm:px-10 md:px-16 lg:px-0 lg:w-3/4 2xl:w-2/3" />
          <div className="relative w-full mt-10 sm:mt-16 md:mt-20 lg:mt-0">
            <div className="absolute left-6 sm:left-1/3 top-1/4 transform -translate-x-8 sm:-translate-x-12 md:-translate-x-16 lg:-translate-x-12 xl:-translate-x-16 -translate-y-0 lg:-translate-y-12 xl:-translate-y-16 z-0 w-full sm:w-auto lg:w-1/3 h-1/3">
              <video ref={videoRef} loop playsInline poster="/home/preview_video.png" className="w-full mx-auto rounded-xl h-full">
                <source src="/home/video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <img src="/home/tv.png" alt="tv img" className="w-full hidden lg:block relative z-10" />
            <img src="/home/tv_phone.png" alt="tv img" className="w-full hidden sm:block lg:hidden relative z-10" />
            <img src="/home/tv_phone_sm.png" alt="tv img" className="w-full sm:hidden relative z-10" />
            <div className="absolute left-1/4 sm:left-1/3 xl:left-1/4 top-2/3 md:top-3/4 lg:top-1/2 transform -translate-x-4 sm:translate-x-0 xl:translate-x-24 2xl:translate-x-28 translate-y-6 xs:translate-y-9 sm:translate-y-8 md:-translate-y-6 lg:translate-y-16 xl:translate-y-20 2xl:translate-y-24 z-10">
              <div className="bg-app-pink p-2 sm:p-2 md:p-3 lg:p-4 2xl:p-5 rounded-full border-2 md:border-3 lg:border-4 2xl:border-5 border-app-yellow w-10 h-10 xs:w-12 xs:h-12 sm:w-10 sm:h-10 md:w-14 md:h-14 lg:w-14 lg:h-14 xl:w-16 xl:h-16 2xl:w-20 2xl:h-20 btn-animation cursor-pointer" onClick={playHandler} onKeyDown={playHandler} role="button" tabIndex={-1}>
                <img src={isPlay ? "/home/icon_pause.png" : "/home/icon_play.png"} alt="play icon" className="w-full transform" />
              </div>
              <div className="flex items-end mt-6 sm:mt-8 lg:mt-6">
                <img src="/home/arrow_top.png" alt="arrow img" className="h-16 sm:h-16 md:h-20 lg:h-24 xl:h-auto" />
                <p className="font-sectione text-app-yellow text-6xl sm:text-6xl md:text-7xl lg:text-7xl xl:text-8xl whitespace-nowrap">
                  Click to watch
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src="/home/cloud.png" alt="cloud img" className="absolute right-0 bottom-0 hidden lg:block h-1/2 md:h-2/3 xl:h-2/3 2xl:h-3/4 z-30" />
      </div>
    )
  }

  const CollectionSection = () => {
    return (
      <div className="collection-section flex flex-col-reverse w-full sm:grid sm:grid-cols-12 text-app-yellow-medium" id="collection">
        <div className="col-span-12 sm:col-span-4 bg-app-purple-medium flex justify-center items-center pt-8 pb-16 sm:py-0">
          <div className="flex sm:flex-col divide-x-2 sm:divide-x-0 sm:divide-y-2 divide-app-yellow-medium">
            <div className="sm:py-8 px-4 xs:px-8 md:px-0 lg:pr-6 xl:pr-12">
              <p className="text-4xl md:text-5xl 2xl:text-6xl font-montserrat-black">2,769</p>
              <p className="font-montserrat-black text-base sm:text-xl">NFTS</p>
            </div>
            <div className="sm:py-8 px-4 xs:px-8 md:px-0 lg:pr-6 xl:pr-12">
              <p className="font-sectione text-2xl sm:text-3xl">Mint Price</p>
              <p className="text-4xl md:text-5xl 2xl:text-6xl font-montserrat-black -translate-y-1">0.039</p>
              <p className="font-montserrat-black text-base sm:text-xl">ETH</p>
            </div>
            <div className="sm:py-8 px-4 xs:px-8 md:px-0 lg:pr-6 xl:pr-12">
              <p className="font-sectione text-2xl sm:text-3xl">Mint Date</p>
              <p className="text-4xl md:text-5xl 2xl:text-6xl font-montserrat-black -translate-y-1">04.25</p>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-8 bg-app-purple-medium sm:bg-app-purple-light px-6 md:px-12 pb-0 pt-16 sm:py-16 xl:p-20 2xl:p-24">
          <p className="text-5xl sm:text-6xl lg:text-7xl xl:text-8xl font-montserrat-black">COLLECTION</p>
          <p className="text-xl md:text-2xl 2xl:text-3xl mt-6 xl:mt-8 2xl:mt-12 sm:leading-relaxed lg:leading-relaxed xl:leading-loose 2xl:leading-loose">
            The IAMSLOTH Collection is a PFP collection of 2,769 unique SLOTHs. These sloths and traits have all been hand-drawn over the last 12 months by artist SLOTH. This project represents a way of life, a more engaging, present and chill lifestyle. We are collaborators, creatives and individuals who are focused on the present moment. We put people first and believe in a patient more focused paced way of life. The sloth fam is a place for you to come rest, chill, engage with good people and remember that life is sometimes more than just light speed and to-do lists. Come chill… All are welcome…
          </p>
        </div>
      </div>
    )
  }

  const SlideSection = () => {
    const firstRef = useRef(null)
    const secondRef = useRef(null)

    useEffect(() => {
      gsap.from(firstRef.current, {
        ease: 'none',
        x: -500,
        transformOrigin: "right center",
        scrollTrigger: {
          trigger: firstRef.current,
          scrub: 1,
          start: "top bottom",
          end: "top top"
        }
      });

      gsap.from(secondRef.current, {
        ease: 'none',
        x: 500,
        transformOrigin: "left center",
        scrollTrigger: {
          trigger: secondRef.current,
          scrub: 1,
          start: "top bottom",
          end: "top top"
        }
      });
    }, []);

    return (
      <>
        <div className="gallery flex-col gap-4 overflow-hidden relative justify-center sm:flex hidden bg-app-purple-light pb-6">
          <div className={`flex gap-4`} ref={firstRef}>
            {galleryData[0].map((img, idx) => (
              <img src={`/home/slide${img}.png`} key={idx} className="w-48 h-48 md:w-64 md:h-64 xl:w-80 xl:h-80 aspect-square" alt="gallery" />
            ))}
          </div>
          <div className={`-ml-96 flex gap-4 bottom-12 sm:bottom-18 `} ref={secondRef}>
            {galleryData[1].map((img, idx) => (
              <img src={`/home/slide${img}.png`} key={idx} className="w-48 h-48 md:w-64 md:h-64 xl:w-80 xl:h-80 aspect-square" alt="gallery" />
            ))}
          </div>
        </div>
        <div className="sm:hidden grid grid-cols-3 grid-rows-4">
          {galleryData[2].map((img, idx) => (
            <img src={`/home/slide${img}.png`} key={idx} className="aspect-square" alt="gallery" />
          ))}
        </div>
      </>
    )
  }

  const TraitsSection = () => {
    return (
      <div className="traits-section relative bg-app-pink-100">
        <img src="/home/traits.png" alt="traits img" className="pt-20 sm:pt-12 md:pt-0 lg:pt-12 xl:pt-0 transform hidden sm:block" />
        <img src="/home/traits_phone.png" alt="traits img" className="pt-20 sm:pt-12 md:pt-0 lg:pt-12 xl:pt-0 transform sm:hidden" />
        <div className="flex flex-col text-app-purple absolute left-1/2 transform -translate-x-1/2 top-12">
          <p className="font-sectione text-3xl md:text-4xl lg:text-5xl ml-12 pt-0 lg:pt-10 2xl:pt-12">Project</p>
          <p className="font-montserrat-black -mt-3 lg:-mt-4 text-5xl sm:text-6xl lg:text-8xl uppercase ">TRAITS</p>
        </div>
      </div>
    )
  }

  const RoadMapSection = () => {
    const carouselRef = useRef(null)
    const [selectedId, setSelectedId] = useState("sloth")
    const [isOpen, setIsOpen] = useState(false)
    const [galleryIndex, setGalleryIndex] = useState(0)
    const [roadMapInfo, setRoadMapInfo] = useState(() => {
      return roadmapList.filter((item) => item.id === selectedId)[0]
    })
    const [galleryInfo, setGalleryInfo] = useState(() => {
      return roadmapList.filter((item) => item.id === selectedId)[0].images
    })

    const chooseRoadMapHandler = (id) => {
      setSelectedId(id)
      setRoadMapInfo(roadmapList.filter((item) => item.id === id)[0])
      carouselRef.current.goToSlide(0)
    }

    const chooseGalleryHandler = (id) => {
      setSelectedId(id)
      setGalleryInfo(roadmapList.filter((item) => item.id === id)[0].images)
      setGalleryIndex(0)
      setIsOpen(true)
    }

    const responsive = {
      mobile: {
        breakpoint: { max: 10000, min: 0 },
        items: 1,
        slidesToSlide: 1
      },
    }

    return (
      <div className="roadmap-section bg-app-purple-light text-app-yellow-medium" id="roadmap">
        <div className="flex flex-col items-center">
          <div className="flex flex-col justify-start">
            <p className="font-sectione text-3xl md:text-4xl lg:text-5xl pt-12">The Sloth</p>
            <p className="font-montserrat-black -mt-3 lg:-mt-4 text-5xl sm:text-6xl lg:text-8xl uppercase text-app-yellow">Road Map</p>
          </div>
          <div className="">
            <p className="font-sectione text-3xl md:text-4xl lg:text-5xl -rotate-2 tracking-wide">
              Use the SLOTH to know the map
            </p>
            <div className="flex justify-end pr-16">
              <img src="/home/arrow_down.png" alt="arrow img" className="h-16" />
            </div>
          </div>
        </div>
        <div className="container mx-auto mt-8 px-6 sm:px-0 xl:px-0">
          <div className="grid grid-cols-12 md:gap-10">
            <div className="col-span-12 md:col-span-6">
              <div className="relative w-full sm:w-2/3 md:w-full xl:w-4/5 mx-auto xl:mx-0 font-sectione">
                <img src="/home/future_utility.png" alt="future img" className="w-full" />
                <div className="hidden md:block">
                  {
                    roadmapList.map((data, idx) => (
                      <div className={`absolute ${data.position}`} key={idx}>
                        <div onClick={() => chooseRoadMapHandler(data.id)} onKeyDown={() => chooseRoadMapHandler(data.id)} role="button" tabIndex={-1}>
                          <img src={data.image} alt={`${data.id} img`} className={`w-16 sm:w-20 md:w-16 lg:w-24 xl:w-28 ${selectedId !== data.id ? "opacity-60" : ""} cursor-pointer`} />
                        </div>
                        <p className={`text-3xl lg:text-4xl text-center mt-2 tracking-wider ${selectedId !== data.id ? "opacity-60" : ""}`} style={{ color: data.color }}>
                          {data.title}
                        </p>
                      </div>
                    ))
                  }
                </div>
                <div className="md:hidden">
                  {
                    roadmapList.map((data, idx) => (
                      <div className={`absolute ${data.position}`} key={idx}>
                        <div onClick={() => chooseGalleryHandler(data.id)} onKeyDown={() => chooseGalleryHandler(data.id)} role="button" tabIndex={-1}>
                          <img src={data.image} alt={`${data.id} img`} className={`w-20 md:w-16 lg:w-24 xl:w-28 ${selectedId !== data.id ? "opacity-60" : ""} cursor-pointer`} />
                        </div>
                        <p className={`text-4xl sm:text-3xl lg:text-4xl text-center mt-2 tracking-wider ${selectedId !== data.id ? "opacity-60" : ""}`} style={{ color: data.color }}>
                          {data.title}
                        </p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 mt-16 md:mt-0 pb-12 hidden md:block">
              <div className="md:pl-8 md:pr-0 xl:px-16 2xl:px-24 w-full sm:w-2/3 md:w-full mx-auto">
                <div>
                  <Carousel
                    ref={carouselRef}
                    swipeable
                    draggable={false}
                    responsive={responsive}
                    infinite={false}
                    customLeftArrow={<LeftArrow color={roadMapInfo.color} />}
                    customRightArrow={<RightArrow color={roadMapInfo.color} />}
                  >
                    {
                      roadMapInfo.images.map((image, idx) => (
                        <div key={idx}>
                          <img src={image} alt="carousel img" className="w-full border-10" style={{ borderColor: roadMapInfo.color }} />
                        </div>
                      ))
                    }
                  </Carousel>
                </div>
                <div className={`flex gap-4 ${roadMapInfo.images.length > 2 ? "justify-between" : ""} mt-6`}>
                  {
                    roadMapInfo.images.map((image, idx) => {
                      if (roadMapInfo.images.length > 3) {
                        if (idx > 0 && idx < 4) {
                          return (
                            <div key={idx} onClick={() => carouselRef.current.goToSlide(idx)} onKeyDown={() => carouselRef.current.goToSlide(idx)} role="button" tabIndex={-1} className="w-1/3">
                              <img src={image} alt="slide img" className="w-full cursor-pointer" />
                            </div>
                          )
                        } else {
                          return null
                        }
                      } else if (idx < 3) {
                        return (
                          <div key={idx} onClick={() => carouselRef.current.goToSlide(idx)} onKeyDown={() => carouselRef.current.goToSlide(idx)} role="button" tabIndex={-1} className="w-1/3">
                            <img src={image} alt="slide img" className="w-full cursor-pointer" />
                          </div>
                        )
                      } else {
                        return null
                      }
                    })
                  }
                </div>
                <p className="text-5xl font-sectione mt-8 tracking-wider" style={{ color: roadMapInfo.color }}>
                  {roadMapInfo.title}
                </p>
                <p className="text-xl lg:text-2xl">
                  {roadMapInfo.description}
                </p>
              </div>
            </div>
            {
              isOpen && (
                <Lightbox
                  mainSrc={galleryInfo[galleryIndex]}
                  nextSrc={galleryInfo[(galleryIndex + 1) % galleryInfo.length]}
                  prevSrc={galleryInfo[(galleryIndex + galleryInfo.length - 1) % galleryInfo.length]}
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() => setGalleryIndex((galleryIndex + galleryInfo.length - 1) % galleryInfo.length)}
                  onMoveNextRequest={() => setGalleryIndex((galleryIndex + 1) % galleryInfo.length)}
                />
              )
            }
          </div>
        </div>
      </div>
    )
  }

  const LeftArrow = ({ onClick, ...rest }) => {
    const { color } = rest

    return (
      <div className="absolute left-0 px-3 py-5 cursor-pointer btn-animation" style={{ backgroundColor: color }} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={-1}>
        <img src="/home/arrow_left.png" alt="arrow img" className="h-8" />
      </div>
    )
  }

  const RightArrow = ({ onClick, ...rest }) => {
    const { color } = rest

    return (
      <div className="absolute right-0 px-3 py-5 cursor-pointer btn-animation" style={{ backgroundColor: color }} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={-1}>
        <img src="/home/arrow_right.png" alt="arrow img" className="h-8" />
      </div>
    )
  }

  const MeetSection = () => {
    return (
      <div className="bg-app-blue flex items-center justify-center mx-auto ">
        <div className="flex flex-col md:flex-row">
          <img src="/home/family.png" alt="family img" className="w-full md:w-1/2 hidden md:block" />
          <div className="w-full md:w-1/2  items-center flex text-app-purple px-6 sm:px-8 md:px-4 md:pl-12 lg:pl-24 py-12 md:pb-6 lg:pb-12">
            <div>
              <p className="font-sectione text-4xl lg:text-5xl pt-6 md:pt-0 lg:pt-12">The OG Sloth</p>
              <div>
                <p className="font-montserrat-black -mt-2 md:-mt-3 lg:-mt-4 text-6xl lg:text-7xl 2xl:text-8xl uppercase ">MEET</p>
                <p className="font-montserrat-black -mt-2 md:-mt-3 lg:-mt-4 text-6xl lg:text-7xl 2xl:text-8xl uppercase ">TIMMY</p>
              </div>
              <p className="text-xl lg:text-2xl 2xl:text-3xl 2xl:w-2/3 mt-4 lg:mt-6 2xl:mt-12">
                Arizona Based Local Award Winning Professional illustrator and visual designer has a passion to create art, communities and content. Timmy Ham AKA SLOTH is a husband and dad of 4 and has been a full time artist for 12yrs and worked with all sorts of major Fortune 500 companies. Timmy has a style that brings bright bold colors and a vibe that will make anyone smile, along with his super powers that bring art and community together for a common good.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const GallerySection = () => {
    const responsive = {
      mobile: {
        breakpoint: { max: 10000, min: 0 },
        items: 1,
        slidesToSlide: 1
      },
    }

    return (
      <>
        <div className="hidden md:flex justify-end relative overflow-hidden">
          <div className="flex flex-col sm:flex-row sm:w-2/3 absolute bottom-0 sm:relative w-1/2 left-0">
            <div className="sm:w-1/2 flex flex-col">
              <img src="/home/img_1.jpg" alt="product_1" />
              <img src="/home/img_3.jpg" alt="product_3" className="h-full" />
            </div>
            <div className="sm:w-1/2 flex flex-col">
              <img src="/home/img_2.jpg" alt="product_2" />
              <img src="/home/img_4.jpg" alt="product_4" className="h-full" />
            </div>
          </div>
          <div className="w-full sm:w-1/3 flex flex-col">
            <img src="/home/img_5.jpg" alt="product_5" />
          </div>
        </div>
        <div className="block md:hidden">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            arrows={false}
          >
            {familyGallery.map((image, index) => (
              <div key={index} style={{ backgroundImage: `url(${image})` }} className="w-full bg-center bg-no-repeat h-[32rem]" />
            ))}
          </Carousel>
        </div>
      </>
    )
  }

  const LogoSection = () => {
    return (
      <div className="bg-app-purple py-12">
        <div className="sm:container flex items-center justify-center mx-auto">
          <div className="flex-wrap items-center justify-center gap-2 sm:gap-6 hidden sm:flex">
            {logoList.map((logo, idx) => (
              <div className="flex items-center justify-center w-1/6 aspect-square" key={idx}>
                <img src={logo.image} alt={logo.name} />
              </div>
            ))}
          </div>
          <img src="/home/logo_panel.png" alt="logo panel" className="sm:hidden" />
        </div>
      </div>
    )
  }

  const TeamSection = () => {
    return (
      <div className="team flex flex-col bg-app-yellow py-16 md:py-20 px-4 sm:px-8 md:px-12">
        <div className="flex flex-col container mx-auto items-center gap-12 sm:gap-16" >
          <div className="flex flex-col text-app-purple ">
            <p className="font-sectione text-3xl md:text-4xl lg:text-5xl">Our</p>
            <p className="font-montserrat-black -mt-3 lg:-mt-4 text-5xl sm:text-6xl lg:text-8xl uppercase ">TEAM</p>
          </div>
          <div className="flex items-center justify-center container mx-auto">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 md:gap-y-8 md:gap-x-6 xl:gap-8">
              {memberList.map((member, idx) => (
                <TeamItem member={member} key={idx} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SignUpSection = () => {
    const [email, setEmail] = useState('')

    return (
      <div className="signup-container flex justify-center items-center relative py-20 md:py-28 lg:py-36">
        <div className="container px-4 sm:px-8 md:px-12">
          <form action="https://gmail.us12.list-manage.com/subscribe/post?u=4e8cd4f4dffb1bdee64ae7eb0&amp;id=c8cfec3332&amp;f_id=004ab4e0f0" method="post" target="_blank">
            <div className="gap-2 md:gap-4 lg:gap-6 flex items-center justify-center flex-col bg-app-purple rounded-3xl p-8 md:p-12 lg:p-24 lg:w-3/4 xl:w-2/3 2xl:w-1/2 mx-auto">
              <h2 className="text-app-yellow text-4xl sm:text-6xl lg:text-8xl uppercase font-montserrat-black">sign up</h2>
              <p className="text-white text-xl sm:text-3xl text-center">Sign up to join the SLOTHVERSE News Letter. Stay up to-date on upcoming releases and launch dates as well and super special top secret info.</p>
              <Input value={email} type="email" setValue={setEmail} name="EMAIL" className=" text-xl sm:text-2xl lg:text-3xl py-2 sm:py-4 lg:py-6 text-center" hint="Your Email Address" />
              <PrimaryButton className=" font-sectione text-4xl sm:text-6xl lg:text-8xl" type="submit">Sign Me Up</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    )
  }

  return (
    <div className="home-container z-10">
      <HomeSection />
      <VideoSection />
      <CollectionSection />
      <SlideSection />
      <TraitsSection />
      <RoadMapSection />
      <MeetSection />
      <GallerySection />
      <LogoSection />
      <TeamSection />
      <SignUpSection />
    </div>
  )
}

export default HomePage
