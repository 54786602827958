import React from 'react'
import Layout from "../components/layout/layout"
import HomePage from '../components/partials/HomePage'

const Home = () =>{
  return(
    <Layout>
      <HomePage />
    </Layout>
  )
}

export default Home