import React from "react";

import "./footer.scss"

const Footer = () => {
  return (
    <footer >
      
    </footer>
  )
}

export default Footer