export const menuList = [
  {
    title:"Collection",
    path:"collection",
    type:"inner"
  },
  {
    title:"Roadmap",
    path:"roadmap",
    type:"inner"
  },
  {
    title:"Team",
    path:"team",
    type:"inner"
  },
]

export const socialLinks = [
  {
    title:"OpenSea",
    link:"https://www.opensea.com"
  },
  {
    title:"Twitter",
    link:"https://www.twitter.com/iamslothnft"
  },
  {
    title:"Discord",
    link:"https://discord.gg/iamsloth"
  }
]
